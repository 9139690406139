<template>
  <div callback />
</template>

<script>
import axios from 'axios';
import { state } from '@shared/utils/storeUtils';
import { trackGtmEvent } from '@/plugins/tracking';
export default {
  name: 'Callback',
  computed: {
    token: state('user', 'gwToken'),
    brandId() {
      return this.$route.query.brandId || '';
    },
    isGoogle() {
      return location.host.includes('google');
    },
    isFacebook() {
      return location.host.includes('facebook');
    },
    isApple() {
      return location.host.includes('apple');
    },
  },
  methods: {
    tokenReplace(r) {
      if(r.isPc) {
        r.opAccessToken = r.accessToken;
        delete r.accessToken;
      }
      return r;
    }
  },
  async mounted() {
    trackGtmEvent({ event: 'Step2aSSOSignupSuccess' });

    let r;
    const postUrl = `https://${location.host}/callback`;
    if(this.isGoogle) {
      r = await axios.post(postUrl, this.$route.query)
        .then(e => {
          this.replaceRouteName('SsoPost', null, this.tokenReplace(e.data));
          return true;
        })
        .catch(e => {
          this.replaceRouteName('SsoPost', null, {error: true, ...e?.data});
        });
    } else if(this.isFacebook) {
      r = await axios.post(postUrl, this.$route.query)
        .then(e => {
          this.replaceRouteName('SsoPost', null, this.tokenReplace(e.data));
          return true;
        })
        .catch(e => {
          this.replaceRouteName('SsoPost', null, {error: true, ...e?.data});
        });
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[callback] { .c(white); }
</style>
import Vue from 'vue';
import gtm from '@/plugins/tracking/gtm';

export const setSegment = () => {
  const id = 'segment-script';
  if(!document.body.querySelector(`#${id}`)) {
    const script = document.createElement('script');
    script.id = id;
    script.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="dONwpNecWOgBSI3gemPlb16mPNorYjr4";;analytics.SNIPPET_VERSION="4.16.1"; analytics.load("dONwpNecWOgBSI3gemPlb16mPNorYjr4"); analytics.page(); }}(); `;
    document.body.appendChild(script);
  }
};
export const setGTM = (brandId, router) => {
  const env = process.env.VUE_APP_ENV;
  if (env !== 'local') {
    const key = brandId === 'GGPOKER' ? process.env.VUE_APP_GTM_GGPUK : brandId === 'WSOP' ? process.env.VUE_APP_GTM_WSOPON : process.env.VUE_APP_GTM_PUBLIC;
    Vue.use(gtm, { id: env === 'test' ? process.env.VUE_APP_GTM_TEST : key, router });
  }
};

export const trackGtmEvent = element => {
  if (Vue.prototype.$gtm?.trackEvent) {
    try {
      Vue.prototype.$gtm?.trackEvent(element);
      if (process.env.VUE_APP_ENV !== 'production') console.log('trackGtmEvent', window.dataLayer, element);
    } catch (e) {
      console.log('error : trackGtmEvent', window.dataLayer, element);
    }
  }
};

export const trackSegment = ({ page, track, identify }) => {
  if (window.analytics) {
    if (page) window.analytics.page(page);
    if (track) {
      if (track.event) window.analytics.track(track.event, track.args);
      else window.analytics.track(track.args);
    }
    if (identify) window.analytics.identify(identify);
  }
};